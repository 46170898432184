import request from '@/utils/request'

/**
 * @param {APP启动图列表}
 */
export function getList(data) {
  return request({
    url: '/superAdmin/pic/index',
    method: 'post',
    data,
  })
}
/**
 * @启用停用启动图
 */

export function toggleStatus(data) {
  return request({
    url: '/superAdmin/pic/status',
    method: 'post',
    data,
  })
}
/**
 * @删除启动图
 */

export function deleteRow(data) {
  return request({
    url: '/superAdmin/pic/delete',
    method: 'post',
    data,
  })
}
/**
 * @启动图详情
 */

export function getDetail(data) {
  return request({
    url: '/superAdmin/pic/view',
    method: 'post',
    data,
  })
}

/**
 * @添加启动图
 */
export function createLaunch(data) {
  return request({
    url: '/superAdmin/pic/create',
    method: 'post',
    data,
  })
}
/**
 * @编辑启动图
 */
export function editLaunch(data) {
  return request({
    url: '/superAdmin/pic/update',
    method: 'post',
    data,
  })
}
/**
 * @版本列表
 */

export function versionList(data) {
  return request({
    url: '/superAdmin/version/index',
    method: 'post',
    data,
  })
}
/**
 * @新增版本
 */
export function addVersion(data) {
  return request({
    url: '/superAdmin/version/create',
    method: 'post',
    data,
  })
}
/**
 * @撤回
 */
export function undoVersion(data) {
  return request({
    url: '/superAdmin/version/back',
    method: 'post',
    data,
  })
}
/**
 * @保存修改
 */
export function editVersion(data) {
  return request({
    url: '/superAdmin/version/update',
    method: 'post',
    data,
  })
}
